import Vue from 'vue'
import VueRouter from 'vue-router'
import MyCapacity from '../views/MyCapacity.vue'
import Reports from '../views/Reports.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'ProjectsHome',
        component: MyCapacity
    },
    {
        path: '/mycapacity',
        name: 'MyCapacity',
        component: MyCapacity
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports
    }


]
// TODO: Look at code splitting routes?

const router = new VueRouter({
    routes
})

export default router
