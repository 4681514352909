import axios from "axios";
import config from "@/config";
import {snackbar} from "@/stores/capacityStore";
import throttle from 'lodash.throttle'

let axiosConfig = {
    headers: {Authorization: `Bearer ${sessionStorage.getItem('jwtToken')}`}
};


/**
 * Will create with POST id is null, uses PUT otherwise
 *
 * @param path trailing path of URL e.g.' /teams'
 * @param data
 * @param id use null to POST
 * @returns {Promise<void>}
 */
export async function postOrPutAxios(path, data, id) {

    if (id) {
        return putAxios(`${path}/${id}`, data)
    } else {

        if (!data.__ob__){
            throw Error('You must pass a reactive object with an id variable so POST can set id')
        }

        const res = await postAxios(path, data)
        data.id = res.id
    }
}

/**
 * Common throttled axios put method
 */
const putAxios = throttle(async (path, data) => {
    try {
        let res = await axios.put(`${config.BACKEND_URL}/${path}`, data, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`Error: ${e}`)
        handleError(e)
    }
}, 5000)

/**
 * Common axios post
 * @param url
 * @param data
 * @returns {Promise<any>}
 */
const postAxios = async (path, data) => {
    try {
        let res = await axios.post(`${config.BACKEND_URL}/${path}`, data, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`Error: ${e}`)
        handleError(e)
    }
}

/**
 * Common axios get
 * @param path
 * @returns {Promise<any>}
 */
export const getAxios = async (path) => {
    try {
        let res = await axios.get(`${config.BACKEND_URL}/${path}`, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`Error: ${e}`)
        handleError(e)
    }
}

/**
 * Common delete
 * @param path
 * @returns {Promise<any>}
 */
export const deleteAxios = async (path) => {
    try {
        let res = await axios.delete(`${config.BACKEND_URL}/${path}`, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`Error: ${e}`)
        handleError(e)
    }
}
function handleError(e) {
    if (e.message.includes('403')) {
        console.log(`Got 403 in error, redirect to login`)
        window.location.href = (`${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
    } else {
        console.log(`Err: ${JSON.stringify(e)}`)
        snackbar.value.text = `${e}. `
        snackbar.value.visible = true
    }
    throw(e)
}
