<template>
  <v-main>
    <v-container>

      <v-row>
        <v-col cols="2">
          <v-combobox v-model="team" :items="['ESE-BUS']" label="Team"/>
        </v-col>
        <v-col cols="2">
          <v-combobox v-if="team" v-model="person" :items="people" label="Employee" @change="loadCapacitySheet"/>
        </v-col>
        <v-col cols="2">
          <v-text-field v-if="person" @change="updateSheet()" label="Productive Hours Per Day"
                        v-model="productiveHoursPerDay"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-if="person" @change="recalculate()" label="Work Days In Period"
                        v-model="startWorkDaysInPeriod"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-if="person" @change="recalculate()" label="Productive Hour Balance"
                        v-model="startProductiveHourBalance"></v-text-field>
        </v-col>
      </v-row>
      <v-tabs v-model="selectedTab" v-if="person" @change="loadCapacitySheet" dark>
        <v-tab v-for="p in periods" :key="p">{{ p }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab" v-if="person">
        <v-tab-item v-for="p in periods" :key="p">
          <v-simple-table v-if="person" dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-right">Work Category</th>
                <th class="text-left">Description</th>
                <th class="text-center">Hours per week</th>
                <th class="text-center">Fixed Hours</th>
                <th class="text-center">Full Days of Effort</th>
                <th class="text-center">Work Days in Period</th>
                <th class="text-center">Productive Hour Balance</th>
                <th class="text-center">Productive hours consumed by line item</th>
                <th class="text-center">Time Category Hours (Total)</th>
                <th class="text-center">Percentage of Work Category</th>
                <th class="text-center">Time Category Percentage</th>
                <th class="text-center"></th>
                <th class="text-center"></th>
                <th class="text-center"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td></td>
                <td class="text-left">Starting balance of productive hours</td>
                <td colspan="3">&nbsp;</td>
                <td>{{ startWorkDaysInPeriod }}</td>
                <td>{{ startProductiveHourBalance }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <template v-for="(category, catIndex) in capacity.categories">
                <tr
                    v-for="(item, itemIndex) in category.items"
                    :key="item.name"
                    :class="{'category-start':itemIndex===0,'category-end':itemIndex===category.items.length-1,'category-even':catIndex%2===1, 'category-odd':catIndex%2===0}"
                >
                  <td v-if="itemIndex===0" :rowspan="category.items.length" class="category-cell text-right">
                    {{ category.description }}
                  </td>
                  <td class="text-left"><input v-model="item.description" @change="updateItemCapacity(catIndex, item)">
                    <v-btn v-if="itemIndex===category.items.length-1" class="float-right" fab x-small width="20px"
                           height="20px" @click="addItem(category)">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="item.description!=='Holidays'"><input v-model="item.hoursPerWeek" size="4"
                                                                  @change="updateItemCapacity(catIndex, item)"></td>
                  <td v-if="item.description!=='Holidays'"><input v-model="item.fixedHours" size="4"
                                                                  @change="updateItemCapacity(catIndex, item)"></td>
                  <td v-if="item.description!=='Holidays'"><input v-model="item.fullDaysEffort" size="4"
                                                                  @change="updateItemCapacity(catIndex, item)"></td>
                  <td v-if="item.description==='Holidays'">{{ item.hoursPerWeek }}</td>
                  <td v-if="item.description==='Holidays'">{{ item.fixedHours }}</td>
                  <td v-if="item.description==='Holidays'">{{ item.fullDaysEffort }}</td>
                  <td>{{ item.workDaysInPeriod }}</td>
                  <td>{{ item.productiveHourBalance }}</td>
                  <td>{{ item.productivHoursConsumedByItem }}</td>
                  <td v-if="itemIndex===0" :rowspan="category.items.length">{{ category.totalTimeCategoryHours }}</td>
                  <td>{{ Math.round(item.percentageOfWorkCategory) }}%</td>
                  <td v-if="itemIndex===0" :rowspan="category.items.length">
                    {{ Math.round(category.timeCategoryPercentage) }}%
                  </td>

                  <td v-if="item.description!=='Holidays'" style="padding: 0">
                    <v-icon small class="mr-2" @click="reorderRows(catIndex, itemIndex, itemIndex - 1)">mdi-arrow-up-thick</v-icon>
                  </td>
                  <td v-if="item.description!=='Holidays'" style="padding: 0">
                    <v-icon small class="mr-2" @click="reorderRows(catIndex, itemIndex, itemIndex + 1)">mdi-arrow-down-thick</v-icon>
                  </td>
                  <td v-if="item.description!=='Holidays'" style="padding: 0">
                    <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
                  </td>
                  <td v-if="item.description==='Holidays'"></td>
                </tr>
              </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>

    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
import * as apiAws from "@/api/apiAws";
import {currentUser} from "@/stores/capacityStore";
import log from "@/util/log";
import {authenticate} from "@/util/util";
import * as apiMock from "@/api/apiMock";

export default {
  name: 'MyCapacity',
  components: {},
  data: () => ({
    status: '',
    productiveHoursPerDay: '',
    startWorkDaysInPeriod: 86,
    startProductiveHourBalance: 600,
    team: '',
    people: [
      {text: 'Kyle Bippus', value: 'kbippus'},
      {text: 'Ribe Ninan', value: 'rninan'},
      {text: 'Valeriya Gadiyak', value: 'vgadiyak'}
    ],
    person: '',
    selectedTab: 0,
    periods: [],
    serialNumber: [],
    capacity: {
      categories: []
    }
  }),
  async created() {
    await authenticate();
    log('*** currentUser.value.user.ldap ', currentUser.value.user.ldap);

    // TODO: Replace with real Periods API when it's implemented
    const availablePeriods = await apiMock.getAxios('periods');
    this.periods = availablePeriods.map(period => period.name);
    this.selectedTab = availablePeriods.findIndex(period => period.active);

    this.recalculate();
  },
  methods: {
    recalculate() {
      let runningWorkDaysInPeriod = this.startWorkDaysInPeriod;
      let runningProductiveHourBalance = this.startProductiveHourBalance;
      let runningTimeCategoryHours = 0;
      for (let category of this.capacity.categories) {
        for (let item of category.items) {
          item.workDaysInPeriod = runningWorkDaysInPeriod -= (item.fullDaysEffort ? item.fullDaysEffort : 0)

          if (item.hoursPerWeek) {
            item.productiveHourBalance = Math.round(runningProductiveHourBalance - ((item.workDaysInPeriod / 5) * item.hoursPerWeek))
          } else if (item.fixedHours) {
            item.productiveHourBalance = runningProductiveHourBalance - item.fixedHours
          } else if (item.fullDaysEffort) {
            item.productiveHourBalance = Math.round(runningProductiveHourBalance - (item.fullDaysEffort * this.productiveHoursPerDay))
          } else {
            item.productiveHourBalance = runningProductiveHourBalance;
          }

          item.productivHoursConsumedByItem = runningProductiveHourBalance - item.productiveHourBalance;

          runningProductiveHourBalance = item.productiveHourBalance;
          runningWorkDaysInPeriod = item.workDaysInPeriod;
        }
        category.totalTimeCategoryHours = this.startProductiveHourBalance - (category.items.length > 0 ? category.items[category.items.length - 1].productiveHourBalance : 0)
        runningTimeCategoryHours += category.totalTimeCategoryHours
        for (let item of category.items) {
          item.percentageOfWorkCategory = item.productivHoursConsumedByItem / category.totalTimeCategoryHours * 100
        }
      }
      for (let category of this.capacity.categories) {
        category.timeCategoryPercentage = category.totalTimeCategoryHours / runningTimeCategoryHours * 100
      }
    },
    async updateSheet() {
      const updateSheetPromise =  apiAws.putAxios(`personCapacitySheet/${this.periods[this.selectedTab]}/${this.person.value}`, {
        productiveHoursPerDay: this.productiveHoursPerDay,
        status: this.status
      });

      let categories = {};
      for (const category of this.capacity.categories) {
        categories[category.description] = category.items.map(item => item.rowId);
      }

      const updateRowOrderPromise = apiAws.putAxios(`personCapacitySheet/${this.periods[this.selectedTab]}/${this.person.value}/rowOrder`, {
        previousSerialNumber: this.serialNumber,
        category1: categories.category1,
        category2: categories.category2,
        category3: categories.category3,
        category4: categories.category4,
        category5: categories.category5
      });

      const results = await Promise.all([updateSheetPromise, updateRowOrderPromise]);
      this.serialNumber = results[1].personCapacitySheetRowOrder.rowOrder.serialNumber;
      this.recalculate();
    },
    addItem(category) {
      category.items.push({
        description: '',
        hoursPerWeek: null,
        fixedHours: null,
        fullDaysEffort: null,
        workDaysInPeriod: null,
        productiveHourBalance: null,
        productivHoursConsumedByItem: null,
        percentageOfWorkCategory: null
      });
      this.recalculate();
    },
    async updateItemCapacity(catIndex, item) {
      const body = {
        person: item.person,
        period: item.period,
        description: item.description,
        workCategory: `category${catIndex + 1}`,
        type: 'directSheetEntry',
        capacity: {
          hoursPerWeek: item.hoursPerWeek,
          fixedHours: item.fixedHours,
          fullDaysOfEffort: item.fullDaysEffort,
          workDaysInPeriod: item.workDaysInPeriod
        }
      };
      console.log(body);
      const doRequest = item.rowId ? apiAws.putAxios : apiAws.postAxios;
      const { personCapacitySheetRow: { rowId } } = await doRequest(`personCapacitySheet/${this.periods[this.selectedTab]}/${this.person.value}/rows${item.rowId ? '/' + item.rowId : ''}`, body);

      if (rowId) {
        item.rowId = rowId;
      }

      this.recalculate()
    },
    deleteItem(item) {
      apiAws.deleteAxios(`personCapacitySheet/${this.periods[this.selectedTab]}/${this.person.value}/rows/${item.rowId}`);
      this.recalculate()
    },
    async loadCapacitySheet() {
      const period = this.periods[this.selectedTab];
      const person = this.person.value;
      if (!this.person || !period) {
        console.warn('Person or period not specified, will not load capacity sheet!');
        return;
      }

      //const {productiveHoursPerDay, status, rows, rowOrder} = await loadAndGetCapacitySheet(this.person.value, period);
      console.log(`Loading sheet data with person == ${person} and period === ${period}`);
      const [{personCapacitySheet}, {personCapacitySheetRows}, {personCapacitySheetRowOrder}] = await Promise.all([
        apiAws.getAxios(`/personCapacitySheet/${period}/${person}`),
        apiAws.getAxios(`/personCapacitySheet/${period}/${person}/rows`),
        apiAws.getAxios(`/personCapacitySheet/${period}/${person}/rowOrder`)
      ]);

      this.productiveHoursPerDay = personCapacitySheet.productiveHoursPerDay;
      this.status = personCapacitySheet.status;
      this.setCapacitySheet(personCapacitySheetRows, personCapacitySheetRowOrder);
    },
    setCapacitySheet(rows, rowOrders) {
      let categories = {};
      const sheetRows = rows.filter(r => r.type === 'directSheetEntry');

      for (const [name, rowIds] of Object.entries(rowOrders)) {
        if (name === 'serialNumber') {
          continue;
        }

        categories[name] = {totalTimeCategoryHours: null, timeCategoryPercentage: null, items: []};

        for (const id of rowIds) {
          const row = sheetRows.find(r => r.rowId === id);
          if (!row) {
            console.log(`No row for ${id} for category ${name}`);
            continue;
          }

          categories[name].items.push({
            rowId: row.rowId,
            description: row.description,
            hoursPerWeek: row.capacity.hoursPerWeek ?? null,
            fixedHours: row.capacity.fixedHours ?? null,
            fullDaysEffort: row.capacity.fullDaysOfEffort ?? null,
            workDaysInPeriod: row.capacity.workDaysInPeriod ?? null,
            productivHoursConsumedByItem: row.capacity.productivHoursConsumedByItem ?? null,
            percentageOfWorkCategory: row.capacity.percentageOfWorkCategory ?? null,
          });
        }

      }

      this.capacity.categories = Object.entries(categories).map(pair => ({
        description: pair[0],
        items: pair[1].items,
        totalTimeCategoryHours: pair[1].totalTimeCategoryHours,
        timeCategoryPercentage: pair[1].timeCategoryPercentage,
      }));
      this.serialNumber = rowOrders.serialNumber;
    },
    async reorderRows(catIndex, sourceIndex, destIndex) {
      if (destIndex < 0) {
        destIndex = 0;
      }

      const rows = this.capacity.categories[catIndex].items;
      rows.splice(destIndex, 0, rows.splice(sourceIndex, 1)[0]);

      await this.updateSheet();
    }
  }
}
</script>
<style scoped>

table {
  border-collapse: collapse;
  border: 3px red solid;
}

tbody tr {
  border-left: 1pt black solid;
  background-color: rgb(217, 234, 211);
}

tbody {
  border-left: 1pt black solid;
}

td {
  border-left: 1px solid grey;
}

.category-odd {
  background-color: rgb(201, 218, 248);
}

.category-even {
  background-color: rgb(217, 234, 211);
}

.category-start > td {
  border-top: 2pt solid black;
}

/*.category-end > td{*/
/*  border-bottom:2pt solid black;*/
/*}*/
/*.category-cell {*/
/*  border-bottom:2pt solid black !important;*/
/*}*/

/*tr:last-of-type > td {*/
/*  border-bottom: 2px solid black;*/
/*}*/
/*tr > td:last-of-type {*/
/*  border-right: 2px solid black;*/
/*}*/
/*table.v-table thead th {*/
/*  font-size: 20px !important;*/
/*}*/
</style>
