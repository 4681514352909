<template>

<div>
  <v-snackbar
      :value="snackbar.visible"
      :timeout="-1"
      bottom
      color="error"
      center
  >
    {{snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</div>
</template>
<script>
import {snackbar} from "@/stores/capacityStore";

export default {
  name: 'MessageSnackBar',
  setup() {

    return {
      snackbar
    }
  }
}
</script>
<style>


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

</style>