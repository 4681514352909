import {ref, computed} from '@vue/composition-api'
//import log from "@/util/log";

const blank_project = {projectId: null, description: null, title: null, requiredTeams: []}
const _current_project = ref(blank_project)

export const current_project = computed({
    get() {
        // loadFromSession('currentProject', _current_project)
        return _current_project.value
    },
    set(newValue) {
        //  saveToSession('currentProject', newValue)
        //log('Set current_project to %j %o', newValue, new Error().stack);
        if (newValue)
            _current_project.value = newValue
        else {
            _current_project.value = blank_project
        }
    }
});
//
// function loadFromSession(sessionKey, reactiveVar) {
//
//     const sessionData = sessionStorage.getItem(sessionKey);
//     log('store - got raw session data %j', sessionData)
//     if (sessionData) {
//         reactiveVar.value = JSON.parse(sessionData)
//         log(`store - got ${sessionKey} from session %j`, reactiveVar)
//     } else {
//         log(`store - ${sessionKey} not yet on session. Got %j`, reactiveVar)
//     }
// }
//
// function saveToSession(sessionKey, newValue) {
//     log(`store - ${sessionKey} to session %j`, newValue)
//     if (newValue) {
//        sessionStorage.setItem(sessionKey, JSON.stringify(newValue));
//     }
// }


const _projects = ref([])

export const projects = computed({
    get() {
        return _projects.value
    },
    set(newValue) {
        _projects.value = newValue
    }
});


const _currentUser = ref({user: {}, team: {}})

export const currentUser = computed({
    get() {
        return _currentUser.value
    },
    set(newValue) {
        _currentUser.value = newValue
    }
});


// Initialize variables here to ensure they are reactive
const _snackbar = ref({'visible': false, 'text': '', 'dummy': 'test'})

export const snackbar = computed({
    get() {
        return _snackbar.value
    },
    set(newValue) {
        _snackbar.value = newValue
    }
});



// Current Period 2021P1
const _current_period = ref('2021P1')

export const currentPeriod = computed({
    get() {
        return _current_period.value
    },
    set(newValue) {
        if (newValue)
            _current_period.value = newValue
        else {
            _current_period.value = ''
        }
    }
});

// Periods
const _periods = ref([])

export const periods = computed({
    get() {
        return _periods.value
    },
    set(newValue) {
        _periods.value = newValue
    }
});
