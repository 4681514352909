<template>
  <v-app>
    <NavTopAppBar/>
    <div id="app">

      <v-container fluid>
        <NavSideBarDrawer/>


        <router-view/>


      </v-container>
      <MessageSnackBar/>
    </div>
  </v-app>
</template>
<script>

import NavSideBarDrawer from "@/components/NavSideBarDrawer";
import NavTopAppBar from "@/components/NavTopAppBar";
import MessageSnackBar from "@/components/MessageSnackBar";
import {current_project} from "@/stores/capacityStore";


export default {
  components: {MessageSnackBar, NavSideBarDrawer, NavTopAppBar},
  setup() {

    return {
      current_project
    }
  },
  data: () => ({
    sidebarMenu: true
  }),
  computed: {}
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
