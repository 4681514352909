import * as store from "@/stores/capacityStore";
import * as apiMock from "@/api/apiMock";
import log from "@/util/log"
import config from "@/config";
import axios from "axios";
import jwt from "jsonwebtoken";

export async function loadUserData(username) {
    // TODO optimize to not loop objects and maybe cache
    const users = await apiMock.getAxios(`users`)
    const user = users.find(u => u.ldap === username);
    if (!user) {
        log(`WARNING: User not found with username ${username}!`);
        return;
    }

    const teams = await apiMock.getAxios(`teams`)
    const team = teams.find(t => t.id === user.teamId);
    if (!team) {
        log(`WARNING: Team not found with teamId ${user.teamId}!`);
        return;
    }
    store.currentUser.value.user = user
    store.currentUser.value.team = team
    log('Set current user: %j', store.currentUser.value)
}

export function login() {
    console.log(`call shib: ${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
    window.location.href = (`${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
}

export async function authenticate() {
    let token = sessionStorage.getItem('jwtToken')
    let expires = sessionStorage.getItem('jwtExpires')

    // If JWT has expired then force user to login again
    // JWT.Verify is not needed if source of JWT is trusted
    if (expires) {
        console.log("LOGGGING IN!")
        log('JWT token will expire in %s hours', (expires * 1000 - Date.now()) / 1000 / 60 / 60)
        if (Date.now() >= expires * 1000) {
            console.log(`Your JWT has expired. Now (${Date.now()}) >= ${expires * 1000}`)
            sessionStorage.removeItem('jwtToken');
            sessionStorage.removeItem('jwtExpires');
            sessionStorage.removeItem('user');
            window.location.href = (`${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
        }
    }

    if (token) {
        log('jwtToken is on the session and not expired, so we are logged in')
        log('jwtToken: %s', token)
        let username = sessionStorage.getItem('user')
        if (!username) {
            log('Load user data since not loaded yet')
            sessionStorage.setItem('loggedInUser', username);
            //this.loggedInUser = username
            await loadUserData(username)
        } else {
            log('User data already loaded')
            // TODO: cache this data
            await loadUserData(username)
            console.log('Setting user');
            sessionStorage.setItem('loggedInUser', username);
            //this.loggedInUser = username
        }
        return;
    }

    // CAS will set the ticket as a URL parameter on the redirect after you login
    let casTicket = getUrlParameter('ticket');
    if (casTicket) {
        log('CAS Ticket %s, Get JWT %s', casTicket, `${config.CAS_LOGIN_SERVICE_URL}?serviceUrl=${config.FRONTEND_URL}`)

        // Only call validation service if the token is not already set.  You only have 30 seconds to validate the
        // CAS ticket and get a valid JWT

        //https://pg22pld06k.execute-api.us-east-1.amazonaws.com/v1/login?serviceUrl=http://localhost:8080&ticket=ST-123
        if (!token) {
            const response = await axios.get(`${config.CAS_LOGIN_SERVICE_URL}`, {
                params: {
                    "ticket": casTicket,
                    "serviceUrl": `${config.FRONTEND_URL}`
                },
                crossdomain: true
            })
            // Store the token and separate and store the decoded token expires, issues, and user
            token = response.data.token
            const decoded = jwt.decode(token);
            console.log(`Decoded: ${JSON.stringify(decoded)}`)
            sessionStorage.setItem('jwtToken', token)
            sessionStorage.setItem('jwtExpires', decoded.exp)
            sessionStorage.setItem('jwtIssued', decoded.iat)
            sessionStorage.setItem('user', decoded.username)
            log('JWT Stored token %j', token)
            log('JWT Issued: %s, Expires: %s, User: %s', decoded.iat, decoded.exp, decoded.username)
            sessionStorage.setItem('loggedInUser', decoded.username);
            //this.loggedInUser = decoded.username
            await loadUserData(decoded.username)
        }
    } else {
        console.log(`call shib: ${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
        window.location.href = (`${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
    }
}

function getUrlParameter(name, url) {
    if (!url) {
        url = location.search
    }
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
