import axios from "axios";

import {snackbar} from "@/stores/capacityStore";
import log from "@/util/log"

export function setup() {}

// Fail fast on 403 so it doesn't retry 5 times for no reason
// axiosRetry(axios, {
//     retries: 5, retryDelay: (retryCount) => {
//         return retryCount * 1000;
//     }
// });
//

axios.interceptors.request.use(request => {

    const {url, method, data} = request
    let o = {}
    o.url = url
    o.method = method
    o.data = data
    log('Request %o', JSON.stringify(o))
    return request
})

axios.interceptors.response.use(response => {
    if (response.config.url.endsWith('/projects')) {
        log('Got projects response %o',response)
        return response  // to verbose
    }
    const {statusText, status, data} = response
    let o = {}
    o.url = statusText
    o.method = status
    o.data = data
    log('Response %j', data)
    return response
})

axios.interceptors.response.use(response => {
    const {status, statusText} = response
    if (status != 200) {
        if (status != 201) {
            snackbar.value.text = `Error ${status}: ${statusText}. `
            snackbar.value.visible = true
        }
    }

    return response
})