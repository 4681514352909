
export default {
    FRONTEND_PORT:8080,
    BACKEND_PORT:3000,
    // BASE_URL: 'http://10.104.160.234',
    // BACKEND_URL: 'http://10.104.160.234:3000',
    // FRONTEND_URL: 'http://10.104.160.234:8080',
    // CAS_SERVICE_URL: 'https://login.umd.edu/cas/login?service=http://10.104.160.234:8080',
    BASE_URL: 'http://localhost',
    BACKEND_URL: 'http://localhost:3000',
    BACKEND_AWS_URL: 'https://pg22pld06k.execute-api.us-east-1.amazonaws.com/v1',
    FRONTEND_URL: 'http://localhost:8080',
    CAS_SERVICE_URL: 'https://login.umd.edu/cas/login',
    CAS_LOGOUT_URL: 'https://login.umd.edu/cas/logout',
    CAS_LOGIN_SERVICE_URL: 'https://pg22pld06k.execute-api.us-east-1.amazonaws.com/v1/login',
    LOCAL_DEBUG_LOGGING: true
}
//TODO: externalize config to do allow property overrides from previous vars