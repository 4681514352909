<template>
  <v-container>

    <v-row className="text-left">
      <v-col cols="3">

        The report goes here

      </v-col>
    </v-row>
  </v-container>
</template>


<script>

import {authenticate} from "@/util/util";

export default {
  components: {},
  created() {
    authenticate();
  }
}
</script>
