<template>
  <v-app-bar app color="#e21833" dark elevation="0">




    <v-spacer></v-spacer>
    <div id="logo-text">
      <a href="https://umd.edu/" style="text-decoration: none; color: inherit;">
        UNIVERSITY OF MARYLAND
      </a>
    </div>

    &nbsp;&nbsp;
    <LoginWidget/>
    &nbsp;
    <v-icon>mdi-account</v-icon>


  </v-app-bar>

</template>
<script>

import LoginWidget from "@/components/LoginWidget";

export default {
  name: 'NavAppBar',
  components: {LoginWidget}
}
</script>
<style>



#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#logo-text {
  position: absolute;
  top: 25%;
  left: 40%;
  padding: 10px !important;
  line-height: 1.3 !important;
  font-family: Crimson, serif !important;
  font-size: 100% !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  color: #fff !important;
}

.aws {
  font-family: Avenir !important;
  color: #fff !important;
  font-weight: bold;
  position: absolute;
  top: 19%;
  right: 10%;
  padding: 10px !important;
}

</style>