import axios from "axios";
import config from "@/config";
import {snackbar} from "@/stores/capacityStore";
import throttle from 'lodash.throttle'
//import axiosRetry from 'axios-retry';

import {setup} from "@/api/axiosSetup";
import log from "@/util/log"
import * as validate from "@/api/validate"
import {v4 as uuidv4} from 'uuid'
// Only setup once in AWS api, not mock, to not duplicate messages
setup()
// API Source: https://gitlab.umd.edu/it-specialprojects/capacitymanagement/api/tree/master/lib

// Fail fast on 403 so it doesn't retry 5 times for no reason
// axiosRetry(axios, {
//     retries: 5, retryDelay: (retryCount) => {
//         return retryCount * 1000;
//     }
// });

let axiosConfig = {
    headers: {Authorization: `Bearer ${sessionStorage.getItem('jwtToken')}`}
};


export function setBearerToken(token) {
    axiosConfig.headers.Authorization = `Bearer ${token}`
}

log(`curl ${config.BACKEND_AWS_URL}`)


/**
 * POST an array
 * @param path
 * @param data
 * @returns {Promise<void>}
 */
export async function postArray(path, id, data) {
    validateArray(path, id, data)
    if (data.value) {
        data = data.value
    }
    log('Axios postArray %s %j', `${path}/${id}`, data)
    await postAxios(`${path}/${id}`, data)
}

/**
 * PUT an array
 * @param path
 * @param id
 * @param data
 * @returns {Promise<void>}
 */
export async function putArray(path, id, data) {
    validateArray(path, id, data)
    if (data.value) {
        data = data.value
    }
    log('Axios putArray %s %j', `${path}/${id}`, data)
    await putAxios(`${path}/${id}`, data)
}

function validateArray(path, id, data) {
    validate.require(id, 'You must specify an id')
    validate.require(path, 'You must specify a path')
    validate.require(data, 'You must pass in data to POST')
    validate.validateReactive(data)
}

export function genUuid() {
    return uuidv4()
}

/**
 * Will create with POST if id is null, uses PUT otherwise
 *
 * @param path trailing path of URL e.g.' /teams'
 * @param data
 * @param id use null to POST
 * @returns {Promise<void>}
 */
export async function postOrPutAxios(path, data, idPropertyName, id, genUuidPrefix, forcePostId) {
    validate.validateReactive(data,)
    if (data.value) {
        data = data.value
    }
    if (!idPropertyName) {
        throw Error('You must provide the property name to set the returned ID to')
    }
    if (id && !forcePostId) {
        return putAxiosThrottle(`${path}/${id}`, data)
    } else {
        log('ForcePostId: %j', forcePostId)
        if (!genUuidPrefix) {
            log('WARNING: You did not specify a genUuidPrefix to ensure the keys are easy to identify in the DB')
        }
        let generatedId = uuidv4()
        if (forcePostId) {
            log('Forcing a POST ID for special case')
            generatedId = forcePostId
        } else if (genUuidPrefix) {
            generatedId = genUuidPrefix + '-' + generatedId
        }

        log('POST to %j %j', `${path}/${generatedId}`, data)
        await postAxios(`${path}/${generatedId}`, data)
        data[idPropertyName] = generatedId;
    }
}

export const putAxios = async (path, data) => {
    let res = null
    try {
        // Use single quotes for curl to work
        logCurl(`curl PUT ${config.BACKEND_AWS_URL}/${path}`, `curl -X PUT -H 'Content-Type: application/json' -d '${JSON.stringify(data)}'  -H 'Authorization: ${axiosConfig.headers.Authorization}' '${config.BACKEND_AWS_URL}/${path}'`)

        res = await axios.put(`${config.BACKEND_AWS_URL}/${path}`, data, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`RES: ${JSON.stringify(res)}`)
        console.log(`Error: ${e}`)
        handleError(e)
    }
}

export const putAxiosThrottle = throttle(putAxios, 5000)


/**
 * Use postOrPutAxios instead
 */
export const postAxios = async (path, data) => {

    let res = null
    try {


        // Use single quotes for curl to work
        logCurl(`curl POST ${config.BACKEND_AWS_URL}/${path}`, `curl -X POST -H 'Content-Type: application/json' -d '${JSON.stringify(data)}'  -H 'Authorization: ${axiosConfig.headers.Authorization}' '${config.BACKEND_AWS_URL}/${path}'`)

        res = await axios.post(`${config.BACKEND_AWS_URL}/${path}`, data, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`RES: ${res}`)
        console.log(`Error: ${e}`)
        handleError(e)
    }
}

/**
 * Common axios get
 * @param path
 * @returns {Promise<any>}
 */
export const getAxios = async (path) => {
    logCurl(`curl GET ${config.BACKEND_AWS_URL}/${path}`, `curl ${config.BACKEND_AWS_URL}/${path}  -H "Authorization: ${axiosConfig.headers.Authorization}"`)


    try {
        let res = await axios.get(`${config.BACKEND_AWS_URL}/${path}`, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`Error: ${e}`)
        handleError(e)
    }
}

/**
 * Common delete
 * @param path
 * @returns {Promise<any>}
 */
export const deleteAxios = async (path) => {
    try {
        let res = await axios.delete(`${config.BACKEND_AWS_URL}/${path}`, axiosConfig)
        return res.data
    } catch (e) {
        console.log(`Error: ${e}`)
        handleError(e)
    }
}

/**
 * Collapse string in logs
 * @param msg
 */
function logCurl(prefix, msg) {
    let curl = {}
    curl.curl = msg
    log(`${prefix} %o`, curl)
}

let redirected = false
function handleError(e) {
    if (e.message.includes('403')) {
        if (!redirected) {
            console.log(`Got 403 in error, need to login`)
            if (typeof window !== 'undefined')
                window.location.href = (`${config.CAS_SERVICE_URL}?service=${config.FRONTEND_URL}`);
            else {
                throw Error('Got 403 unauthorized, verify you are using a fresh bearer token')
            }
        }
        else{
            console.log('already redirected to CAS login, will not redirect again')
        }
    } else {

        if (snackbar) {
            console.log(`Err: ${JSON.stringify(e)}`)
            snackbar.value.text = `${e}. `
            snackbar.value.visible = true
        } else {
            throw e
        }
    }
    throw(e)
}
