<template>


  <v-navigation-drawer
      permanent
      :mini-variant.sync="mini"
      expand-on-hover
      left
      app

  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-title>John Leider</v-list-item-title>

      <v-btn
          icon
          @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <v-list
        dense
        rounded
    >

      <router-link style="text-decoration: none; color: inherit;" to="/mycapacity">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gauge</v-icon>
          </v-list-item-icon>

          <v-list-item-content>

            <v-list-item-title>Capacity</v-list-item-title>

          </v-list-item-content>
        </v-list-item>
      </router-link>
      <router-link style="text-decoration: none; color: inherit;" to="/reports">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file-table</v-icon>
          </v-list-item-icon>

          <v-list-item-content>

            <v-list-item-title>Reports</v-list-item-title>

          </v-list-item-content>
        </v-list-item>
      </router-link>


    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block v-on:click.prevent="logout">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>

</template>
<script>

import config from "@/config";

export default {
  name: 'NavProjects',

  data() {
    return {
      drawer: true,
      mini: true,
    }
  },
  methods: {
    logout() {
      sessionStorage.removeItem('jwtToken');
      sessionStorage.removeItem('jwtExpires');
      sessionStorage.removeItem('user');
      window.location.href = (`${config.CAS_LOGOUT_URL}`);
    }
  }
}

</script>