<template>
  <div>
    <a href="#" v-on:click.prevent="login" style="color: white" class="text-decoration-none" v-if="!loggedInUser">
      Login
    </a>
    <div v-if="loggedInUser" style="color: white" class="text-decoration-none">
      {{ loggedInUser }}
    </div>
  </div>
</template>
<script>
import {login} from "@/util/util";

export default {
  name: 'LoginWidget',
  props: {
    current_project: {}
  },
  data: () => ({
    loggedInUser: null,
    casTicket: null
  }),
  async created() {
    //await authenticate();
    this.loggedInUser = sessionStorage.getItem('loggedInUser');
  },
  methods: {
    getUrlParameter(name, url) {
      if (!url) {
        url = location.search
      }
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(url);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    login() {
      login();
    }
  }, mounted() {

  }

}
</script>
